





















import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
    name: 'KDrawerList',
    props: {
        navigations: {
            type: Array as PropType<any[]>,
            required: true
        },
    },
    setup() {
        //

        return {
            //
        }
    }
})
