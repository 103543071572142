







































import {
  computed,
  defineComponent,
  Ref,
  ref
} from '@vue/composition-api';
import KDrawer from '../components/navigation/KDrawer.vue';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import KPageTransition from '../components/page/KPageTransition.vue';
import KBottomNavigation from '../components/navigation/KBottomNavigation.vue';
import { isMobile } from '../helpers/useBreakpoint';
import { useAuthStore } from '@/modules/auth/services/store';
import { loadProfileFile } from '../helpers/loader';
import KText from '../components/typography/KText.vue';

export default defineComponent({
  components: { KDrawer, KPageTransition, KBottomNavigation, KText },
  name: 'AppLayout',
  setup() {
    const uiState = useUiStateStore();
    const authStore = useAuthStore();

    const navigationList = {
      dashboard: {
        text: 'Dashboard',
        icon: 'mdi-view-dashboard-outline',
        to: '/app',
        exact: true,
        onclick: () => null
      },
      industry: {
        text: 'Industri',
        icon: 'mdi-city-variant-outline',
        to: '/app/industri',
        exact: false,
        onclick: () => null
      },
      // surveyForm: {
      //   text: 'Form Survey',
      //   icon: 'mdi-clipboard-text-outline',
      //   to: '/app/survey',
      //   exact: false,
      //   onclick: () => null
      // },
      classification: {
        text: 'KBLI',
        icon: 'mdi-group',
        to: '/app/kbli',
        exact: false,
        onclick: () => null
      },
      unit: {
        text: 'Satuan',
        icon: 'mdi-format-list-bulleted-type',
        to: '/app/satuan',
        exact: false,
        onclick: () => null
      },
      commodity: {
        text: 'Komoditas',
        icon: 'mdi-checkbox-multiple-outline',
        to: '/app/komoditas',
        exact: false,
        onclick: () => null
      },
      marketingArea: {
        text: 'Wilayah Pemasaran',
        icon: 'mdi-bullseye-arrow',
        to: '/app/wilayah-pemasaran',
        exact: false,
        onclick: () => null
      },
      finance: {
        text: 'Pembiayaan',
        icon: 'mdi-finance',
        to: '/app/pembiayaan',
        exact: false,
        onclick: () => null
      },
      meta: {
        text: 'Pengaturan',
        icon: 'mdi-cog-outline',
        to: '/app/pengaturan',
        exact: false,
        onclick: () => null
      },
      user: {
        text: 'Pengguna',
        icon: 'mdi-account-multiple-outline',
        to: '/app/user',
        exact: false,
        onclick: () => null
      },
      map: {
        text: 'Map',
        icon: 'mdi-map-outline',
        to: '/app/map',
        exact: false,
        onclick: () => null
      },
      signOut: {
        text: 'Keluar',
        icon: 'mdi-logout-variant',
        exact: false,
        onclick: () => authStore.signOut()
      }
    };
    const navigations = computed(() => {
      let userNavigations: any[] = [];

      const {
        dashboard,
        industry,
        // surveyForm,
        classification,
        unit,
        commodity,
        marketingArea,
        user,
        signOut,
        finance,
        meta,
        map
      } = navigationList;

      const role = useAuthStore().role;
      const isAdmin = role == 'ADMIN';
      const isEmployee = role == 'EMPLOYEE';
      const isIndustry = role == 'INDUSTRY';
      const isVerifier = role == 'VERIFIER';

      if (isAdmin)
        userNavigations = [
          dashboard,
          industry,
          map,
          meta,
          // surveyForm,
          classification,
          unit,
          commodity,
          marketingArea,
          finance,
          user,
          signOut
        ];

      if (isEmployee)
        userNavigations = [dashboard, industry, map, signOut];

      if (isIndustry) userNavigations = [dashboard, signOut];

      if (isVerifier)
        userNavigations = [dashboard, industry, map, signOut];

      return userNavigations;
    });

    return {
      uiState,
      navigations,
      isMobile,
      loadProfileFile,
      authStore
    };
  }
});
