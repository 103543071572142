















































import {
  computed,
  defineComponent,
  PropType
} from '@vue/composition-api';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import KDrawerList from './KDrawerList.vue';

export default defineComponent({
  components: { KDrawerList },
  name: 'KDrawer',
  props: {
    value: {
      type: null,
      required: true
    },
    navigations: {
      type: Array as PropType<any[]>,
      required: true
    },
    miniVariant: {
      type: Boolean,
      default: false
    },
    clipped: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    app: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    floating: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'light'
    },
    'show-logo': {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get: () => {
        return props.value;
      },
      set: (newValue) => {
        emit('input', newValue);
      }
    });

    return {
      modelValue,
      isMobile
    };
  }
});
