



























































import useModelValue from '@/@core/helpers/modelValue';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import KDialog from '../dialogs/KDialog.vue';

export default defineComponent({
  components: { KDialog },
  name: 'KBottomNavigation',
  props: {
    navigations: {
      type: Array as PropType<any[]>,
      default: () => []
    }
  },
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });
    const showOtherDialog = ref(false);

    return {
      modelValue,
      showOtherDialog
    };
  }
});
